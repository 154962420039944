<template>
  <div class="up-simple-card up-request-card">
    <div class="up-simple-card__header">
      <span>№ {{ request.number }}</span>
      <span>Доступ до: {{ request.dateUntil | ruDate("d.m.Y") }}</span>
    </div>
    <p><span>Клиент:</span> {{ request.customer.name }}</p>
    <p><span class="is-pk-hidden">Курс:</span> {{ request.course.name }}</p>
    <p>
      <span class="is-pk-hidden">Слушатели:</span>
      {{ request.listeners.length }}
    </p>
    <p>
      <span class="is-pk-hidden">Статус:</span>
      <el-tag type="success">
        {{ statusTranslated(request.orderStatus) }}
      </el-tag>
    </p>
    <p class="up-request-card__analytics">
      <span>Аналитика:</span>
      <span class="is-orange">{{ request.listenerAnalytics.notStarted }}</span>
      /{{ request.listenerAnalytics.active }}/
      <span class="is-green">{{ request.listenerAnalytics.done }}</span>
    </p>
    <div class="up-request-card__actions">
      <el-button
        v-if="request.orderStatus === 'active'"
        class="is-error"
        icon="el-icon-video-pause"
        type="text"
        @click="$emit('open-modal', request.id, false)"
      >
        Остановить заявку
      </el-button>
      <el-button
        v-else
        class="is-success"
        icon="el-icon-video-play"
        type="text"
        @click="$emit('open-modal', request.id)"
      >
        Включить заявку
      </el-button>
      <router-link :to="`/manager/requests/${request.id}`">
        <i class="el-icon-edit" role="button" />
        <span>Редактировать заявку</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import requestStatuses from "@/enums/requestStatuses";

export default {
  name: "RequestsCards",
  props: {
    request: Object,
  },
  methods: {
    statusTranslated(status) {
      const s = requestStatuses.find((s) => s.state === status);

      return s ? s.text : status;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/manager/requests/request-cards.scss";
</style>