export default [
  {
    state: 'closed',
    text: 'Закрыт',
  },
  {
    state: 'deleted',
    text: 'Удален',
  },
  {
    state: 'active',
    text: 'Активна',
  },
  {
    state: 'inactive',
    text: 'Не активна',
  },
  {
    state: 'requested',
    text: 'Запрошено',
  },
]