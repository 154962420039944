<template>
  <el-dropdown class="up-sort-block" trigger="click"  @command="$emit('sort', $event)">
    <i class="up-sort-block__icon el-icon-s-operation" />
    <el-dropdown-menu slot="dropdown">
      <h3 class="up-sort-block__title">Сортировка по:</h3>
      <el-dropdown-item v-for="s in sortParams" :key="s.key" :command="s.key">
        {{ s.text }}
        <span :class="activeSortClass(s.key)">
          <i class="el-icon-top" /> <i class="el-icon-bottom" />
        </span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "AppSort",
  props: {
    sortParams: Array,
    sortBy: String,
    sortOrder: String,
  },
  methods: {
    activeSortClass(key) {
      if (!this.sortBy.includes(key)) return;

      return this.sortOrder === "asc" ? "is-top-active" : "is-bottom-active";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/app-sort.scss";
</style>