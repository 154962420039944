<template>
  <div class="up-manager">
    <header class="up-main-header">
      <h1 class="up-main-title">Мои заявки</h1>
      <el-input
        v-model="searchValue"
        prefix-icon="el-icon-search"
        placeholder="Поиск по заказчикам и курсам"
        @change="filterHandler"
      />
    </header>
    <template v-if="requests.length > 0">
      <AppSort
        :sort-params="sortParams"
        :sort-by="sortBy"
        :sort-order="sortOrder"
        @sort="sortHandler($event, 'fetchRequests')"
      />
      <AppBlockAsTable class="up-manager__requests up-requests">
        <template slot="header">
          <p
            class="up-requests__number"
            :class="activeSortClass('number')"
            @click="sortHandler('number', 'fetchRequests')"
          >
            Номер <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p
            class="up-requests__created"
            :class="activeSortClass('created')"
            @click="sortHandler('created', 'fetchRequests')"
          >
            Создана <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p
            class="up-requests__customer"
            :class="activeSortClass('customer')"
            @click="sortHandler('customer', 'fetchRequests')"
          >
            Заказчик <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p
            class="up-requests__course"
            :class="activeSortClass('course')"
            @click="sortHandler('course', 'fetchRequests')"
          >
            Курс <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <!--p
            class="up-requests__partner"
            :class="activeSortClass('partner')"
            @click="sortHandler('partner', 'fetchRequests')"
          >
            Партнер <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p-->

          <p class="up-requests__listeners"><i class="el-icon-user" /></p>

          <p class="up-requests__status"><i class="el-icon-video-play" /></p>
          <p class="up-requests__analytics"><i class="el-icon-s-data" /></p>
          <p class="up-requests__date">Доступ до</p>
          <p class="up-requests__actions" />
        </template>
        <div v-for="r in requests" :key="r.id" class="up-table__item">
          <p class="up-requests__number">{{ r.number }}</p>
          <p class="up-requests__created_value">
            {{ r.created | ruDate("d.m.Y") }} <br />
            {{ r.created | ruDate("H:i:s") }}
          </p>
          <p class="up-requests__customer">{{ r.customer.name }}</p>
          <p class="up-requests__course">{{ r.course.name }}</p>
          <!--p class="up-requests__partner">{{ r.partner.name }}</p-->
          <p class="up-requests__listeners">{{ r.listeners.length }}</p>
          <p class="up-requests__status">
            <el-tag type="success">
              {{ statusTranslated(r.orderStatus) }}
            </el-tag>
          </p>
          <p class="up-requests__analytics">
            <span class="is-orange">{{ r.listenerAnalytics.notStarted }}</span>
            /{{ r.listenerAnalytics.active }}/
            <span class="is-green">{{ r.listenerAnalytics.done }}</span>
          </p>
          <p class="up-requests__date">{{ r.dateUntil | ruDate("d.m.Y") }}</p>
          <p class="up-requests__actions">
            <el-button
              v-if="r.orderStatus === 'active'"
              class="is-error"
              icon="el-icon-video-pause"
              type="text"
              @click="openModalHandler(r.id, false)"
            />
            <el-button
              v-else
              class="is-success"
              icon="el-icon-video-play"
              type="text"
              @click="openModalHandler(r.id)"
            />
            <router-link :to="`/manager/requests/${r.id}`">
              <i class="el-icon-edit" />
            </router-link>
          </p>
        </div>
      </AppBlockAsTable>
      <div class="up-manager__requests-cards">
        <RequestCards
          v-for="r in requests"
          :key="`${r.id}-mob`"
          :request="r"
          @open-modal="openModalHandler"
        />
      </div>
      <el-pagination
        v-if="totalSize > perPage"
        background
        layout="prev, pager, next"
        :total="totalSize"
        :current-page.sync="currentPage"
        :page-size="perPage"
        @current-change="fetchRequests"
      />
    </template>
    <p v-else-if="!loading" class="up-empty-list-text">Список пуст</p>
    <AppSimpleModal
      :title="isActivate ? 'Включение заявки' : 'Остановка заявки'"
      :visible.sync="modalVisible"
      :action-text="isActivate ? 'Запустить' : 'Остановить'"
      @close="modalVisible = false"
      @action="activateRequest"
    >
      {{ isActivate ? "Возобновить" : "Остановить" }} выполнение заявки
    </AppSimpleModal>
  </div>
</template>

<script>
import AppBlockAsTable from "@/components/AppBlockAsTable";
import AppSimpleModal from "@/components/AppSimpleModal";
import AppSort from "@/components/AppSort";
import RequestCards from "@/components/manager/requests/RequestCards";

import requestStatuses from "@/enums/requestStatuses";

import sortMixin from "@/mixins/sort.mixin";

import { mapActions } from "vuex";

export default {
  name: "ManagerRequests",
  mixins: [sortMixin],
  components: { AppBlockAsTable, RequestCards, AppSimpleModal, AppSort },
  data: () => ({
    loading: true,
    searchValue: "",
    modalVisible: false,
    requestId: null,
    requests: [],
    isActivate: false,
    perPage: 10,
    currentPage: 1,
    totalSize: 0,
    sortParams: [
      {
        text: "Номеру",
        key: "number",
      },
      {
        text: "Заказчику",
        key: "customer",
      },
      {
        text: "Курсу",
        key: "course",
      },
      /*{
        text: "Партнеру",
        key: "partner",
      },*/
    ],
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
    async fetchRequests() {
      this.$nprogress.start();

      try {
        const { data: requests } = await this.$axios.get("/manager/orders", {
          params: {
            pageSize: this.perPage,
            pageNumber: this.currentPage - 1,
            sortBy: this.sortBy,
            sortOrder: this.sortOrder,
            q: this.searchValue,
          },
        });

        this.requests = requests.items || [];
        this.totalSize = requests.totalSize;

        this.setListenerAnalytics();
      } catch (e) {
        //
        console.log(e);
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
    filterHandler() {
      this.currentPage = 1;
      this.fetchRequests();
    },
    setListenerAnalytics() {
      this.requests = this.requests.map((r) => {
        const listenerAnalytics = r.listeners.reduce(
          (acc, curr) => {
            if (!curr.flow || curr.flow.flowState == "none") acc.notStarted++;
            else if (curr.flow.dateDone) acc.done++;
            else acc.active++;
            return acc;
          },
          {
            done: 0,
            active: 0,
            notStarted: 0,
          }
        );

        return { ...r, listenerAnalytics };
      });
    },
    async activateRequest() {
      const method = this.isActivate ? "unlock" : "lock";

      try {
        const text = this.isActivate ? "активирована" : "остановлена";

        const { data: res } = await this.$axios.patch(
          `/manager/order/${this.requestId}/${method}`
        );

        const request = this.requests.find((r) => r.id === res.id);

        request.orderStatus = res.orderStatus;

        this.$message({
          message: `Поздравляем! Заявка успешно ${text}.`,
          type: "success",
        });
      } catch (e) {
        const text = this.isActivate ? "активировать" : "остановить";

        this.$message({
          message: `Не удалось ${text} заявку.`,
          type: "error",
        });
      } finally {
        this.modalVisible = false;
      }
    },
    statusTranslated(status) {
      const s = requestStatuses.find((s) => s.state === status);

      return s ? s.text : status;
    },
    openModalHandler(id, isActivate = true) {
      this.isActivate = isActivate;
      this.requestId = id;
      this.modalVisible = true;
    },
  },
  created() {
    this.setNavigationText("Страница заявок");

    this.fetchRequests();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/manager/requests/index.scss";
</style>