export default {
  data: () => ({
    sortBy: '',
    sortOrder: '',
  }),
  methods: {
    sortHandler(key, cbName) {
      this.currentPage = 1;
      
      if (this.sortBy.includes(key)) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        this[cbName]();

        return;
      }
      this.sortBy = key;
      this.sortOrder = 'asc';

      this[cbName]();
    },
    activeSortClass(key) {
      if (!this.sortBy.includes(key)) return;

      return this.sortOrder === 'asc' ? 'is-top-active' : 'is-bottom-active';
    }
  },
}