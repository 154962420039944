import { render, staticRenderFns } from "./AppBlockAsTable.vue?vue&type=template&id=94f45ace&scoped=true"
import script from "./AppBlockAsTable.vue?vue&type=script&lang=js"
export * from "./AppBlockAsTable.vue?vue&type=script&lang=js"
import style0 from "./AppBlockAsTable.vue?vue&type=style&index=0&id=94f45ace&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94f45ace",
  null
  
)

export default component.exports