<template>
  <div class="up-table">
    <div v-if="!!$slots.header" class="up-table__header">
      <slot name="header" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "BlockAsTable",
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/app-block-as-table.scss";
</style>