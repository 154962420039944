<template>
  <el-dialog
    center
    modal-centered
    custom-class="up-simple-modal"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <p class="up-simple-modal__text"><slot /></p>
    <slot name="body" />
    <footer class="up-simple-modal__buttons">
      <el-button type="danger" @click="$emit('close')">Отмена</el-button>
      <el-button type="success" @click="$emit('action')">
        {{ actionText }}
      </el-button>
    </footer>
  </el-dialog>
</template>

<script>
export default {
  name: "AppRemoveModal",
  props: {
    actionText: {
      type: String,
      default: "Удалить",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/app-simple-modal.scss";
</style>